import { styled } from '@common/utils/styles'
import { Popover } from '@common/components'

export const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    color: 'white',
    borderRadius: '15px',
    backgroundColor: '#343443',
    padding: theme.spacing(2),
    maxWidth: '250px',
    overflow: 'visible',
    '&:before': {
      content: '""',
      backgroundColor: '#4D4D5A',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      right: '50%',
      width: 10,
      height: 10,
      transform: 'translate(50%,50%) rotate(45deg)',
    },
  },
}))
