import {
  AVAILABILITY_ROUTE,
  BENEFIT,
  BIO_ROUTE,
  CHECKOUT_SUCCESS_ROUTE,
  COMMUNITY_ROUTE,
  COMPANY_ACCOUNT_ROUTE,
  CUSTOM_OPTION_ROUTE,
  DOCUMENTS_ROUTE,
  EVENT_ROUTE,
  FEEDBACK_ROUTE,
  FEEDBACK_SERVICES_ROUTE,
  FIND_A_TEACHER_ROUTE,
  FORGET_ROUTE,
  GROUP_LESSON_ROUTE,
  HOME_ROUTE,
  LANGUAGES_ROUTE,
  LIVE_BOARDS_ROUTE,
  LOGIN_ROUTE,
  MY_CLASSROOMS_ROUTE,
  NEXT_EVENT,
  NOTES_ROUTE,
  PAYMENT_ROUTE,
  PERSONAL_ROUTE,
  PREFERRED_ACCENT_ROUTE,
  PREFERRED_TEACHER,
  PRICING_ROUTE,
  PROFILE_NOTIFICATION_ROUTE,
  PROFILE_ROUTE,
  QUIZ_VOCAB_ROUTE,
  RECORDINGS_ROUTE,
  REGISTER_COMPANY_ROUTE,
  REGISTER_ROUTE,
  SETTINGS_ROUTE,
  SIMPLE_LOGIN_ROUTE,
  SUBSCRIPTION_ROUTE,
  SUMMARY_ROUTE,
  TEACHER_AFFILIATION_OFFERS,
  TEACHER_CONNECT_ROUTE,
  TEACHER_PRICES,
  TESTMYSELF_ROUTE,
  TRY_PENGGUIN_ROUTE,
  USERNAME_ROUTE,
  VERIFY_ROUTE,
  VIDEO_ROUTE,
  WEBLINKS_ROUTE,
} from '@common/constants/routes'
import { createBrowserRouter } from '@lib/router'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { lazy, Suspense } from 'react'

import AppLayout from '@common/layout/app-layout'
import AuthLayout from '@common/layout/auth-layout'
import DashboardLayout from '@common/layout/dashboard-layout'
import ErrorBoundary from '@common/layout/error-boundary'
import EventLayout from '@common/layout/event-layout'
import PageLayout from '@common/layout/page-layout'
import ProfileLayout from '@common/layout/profile-layout'
import RootLayout from '@common/layout/root-layout'
import { CommunityPlaceholder } from '@pages/community'
import { documentsLoader } from '@pages/dashboard/documents'
import { liveboardsLoader, LiveboardsPlaceholder } from '@pages/dashboard/liveboards'
import { notesLoader, StickyNotesPlaceholder } from '@pages/dashboard/sticky-notes'
import { vocabularyLoader, VocabularyPlaceholder } from '@pages/dashboard/vocabulary'
import Weblinks, { WeblinksPlaceholder } from '@pages/dashboard/weblinks'
import { eventLoader, EventPlaceholder } from '@pages/event'
import { FindATeacherPlaceholder } from '@pages/find-a-teacher'
import { ForgetPlaceholder } from '@pages/forget'
import groupLessonLoader from '@pages/group-lesson/loader'
import { HomePagePlaceholder, teacherHomeLoader } from '@pages/home-page-teacher'
import { HomePageTeacher } from '@pages/home-page-teacher/home-page-teacher'
import { liveEventLoader } from '@pages/live-event'
import { LoginPlaceholder } from '@pages/login'
import { MyClassroomsPlaceholder } from '@pages/my-classrooms'
import { NotFoundPlaceholder } from '@pages/not-found'
import PaymentSuccessPlaceholder from '@pages/payment-success/placeholder'
import { PricingPlaceholder } from '@pages/pricing'
import { profileLoader, ProfilePlaceholder } from '@pages/profile'
import Accent from '@pages/profile/acccent'
import { AvailabilityPlaceholder } from '@pages/profile/availability'
import { BioPlaceholder } from '@pages/profile/bio'
import { languagesLoader, LanguagesPlaceholder } from '@pages/profile/languages'
import notificationLoader from '@pages/profile/notification-settings/loader'
import { PersonalDetailsPlaceholder } from '@pages/profile/personal-details'
import { PreferredTeacherPlaceholder } from '@pages/profile/preferred-teacher'
import { ReferralLinkPlaceholder } from '@pages/profile/referral-link'
import { SettingsPlaceholder } from '@pages/profile/settings'
import { FeedbackPlaceholder } from '@pages/dashboard/feedback'
import { VideoPlaceholder } from '@pages/profile/videos'
import { RegisterPlaceholder } from '@pages/register'
import { SubscriptionPlaceholder } from '@pages/subscription-individuals'
import { TryPengguinPlaceholder } from '@pages/try-pengguin'
import { VerifyPlaceholder } from '@pages/verify'
import { isUNSW } from '../utils/unswUtils'
import HomePlaceholder from '@pages/home/placeholder'
const Feedback = lazy(() => import('@pages/dashboard/feedback'))
const FeedbackById = lazy(() => import('@pages/dashboard/feedback/[feedback_id]'))
const Login = lazy(() => import('@pages/login'))
const Main = lazy(() => import('@pages/main'))
const Register = lazy(() => import('@pages/register'))
const TryPengguin = lazy(() => import('@pages/try-pengguin'))
const Verify = lazy(() => import('@pages/verify'))
const Forget = lazy(() => import('@pages/forget'))
const Settings = lazy(() => import('@pages/profile/settings'))
const MyClassrooms = lazy(() => import('@pages/my-classrooms'))
const Community = lazy(() => import('@pages/dashboard/community'))
const UserId = lazy(() => import('@pages/dashboard/community/[user_id]'))
const Overview = lazy(() => import('@pages/dashboard/overview'))
const Summary = lazy(() => import('@pages/(unsw)/summary'))
const SummaryById = lazy(() => import('@pages/(unsw)/summary/[summary_id]'))
const StickyNotes = lazy(() => import('@pages/dashboard/sticky-notes'))
const Documents = lazy(() => import('@pages/dashboard/documents'))
const Liveboards = lazy(() => import('@pages/dashboard/liveboards'))
const Event = lazy(() => import('@pages/event'))
const LiveEvent = lazy(() => import('@pages/live-event'))
const Demo = lazy(() => import('@pages/demo'))
const Home = lazy(() => import('@pages/home'))
const Bio = lazy(() => import('@pages/profile/bio'))
const Vocabulary = lazy(() => import('@pages/dashboard/vocabulary'))
const QuizVocabularyView = lazy(() => import('@pages/dashboard/quiz-vocabulary'))
const PersonalDetails = lazy(() => import('@pages/profile/personal-details'))
const TeacherOptions = lazy(() => import('@pages/profile/teacher-options'))
const NotificationSettings = lazy(() => import('@pages/profile/notification-settings'))
const TeacherConnectAccount = lazy(() => import('@pages/profile/teacher-connect-account'))
const TeacherPrices = lazy(() => import('@pages/profile/teacher-prices'))
const FeedbackService = lazy(() => import('@pages/profile/feedback-service'))
const Availability = lazy(() => import('@pages/profile/availability'))
const AffiliationOffers = lazy(() => import('@pages/profile/affliation-offers'))
const BenefitLink = lazy(() => import('@pages/profile/referral-link'))
const PreferredTeacher = lazy(() => import('@pages/profile/preferred-teacher'))
const Videos = lazy(() => import('@pages/profile/videos'))
const Languages = lazy(() => import('@pages/profile/languages'))
const FindATeacher = lazy(() => import('@pages/find-a-teacher'))
const GroupLesson = lazy(() => import('@pages/group-lesson'))
const NotFound = lazy(() => import('@pages/not-found'))
const Recordings = lazy(() => import('@pages/dashboard/recordings'))
const Pricing = lazy(() => import('@pages/pricing'))
const Payment = lazy(() => import('@pages/payment'))
const PaymentSuccess = lazy(() => import('@pages/payment-success'))
const Subscription = lazy(() => import('@pages/profile/subscription'))
const TestMyself = lazy(() => import('@pages/dashboard/test-myself'))
const SimpleLogin = lazy(() => import('@pages/slogin'))
const SubscriptionIndividuals = lazy(() => import('@pages/subscription-individuals'))
const CompanyStart = lazy(() => import('@pages/company/company-start'))
const CompanyAccount = lazy(() => import('@pages/company/company-account'))

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <AuthLayout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            element: (
              <PageLayout
                public
                title='Welcome to I Speak'
                fallback={isUNSW() ? <LoginPlaceholder /> : <MyClassroomsPlaceholder />}
              >
                {isUNSW() ? <Login /> : <Main />}
              </PageLayout>
            ),
          },
          {
            path: LOGIN_ROUTE,
            element: (
              <PageLayout public title='Welcome to I Speak' fallback={<LoginPlaceholder />}>
                <Login />
              </PageLayout>
            ),
          },
          {
            path: REGISTER_ROUTE,
            element: (
              <PageLayout public title='Register' fallback={<RegisterPlaceholder />}>
                <Register />
              </PageLayout>
            ),
          },
          {
            path: VERIFY_ROUTE,
            element: (
              <PageLayout public title='Verify your account' fallback={<VerifyPlaceholder />}>
                <Verify />
              </PageLayout>
            ),
          },
          {
            path: TRY_PENGGUIN_ROUTE,
            element: (
              <PageLayout public title='Try I Speak' fallback={<TryPengguinPlaceholder />}>
                <TryPengguin />
              </PageLayout>
            ),
          },
          {
            path: FORGET_ROUTE,
            element: (
              <PageLayout public title='Forget your password' fallback={<ForgetPlaceholder />}>
                <Forget />
              </PageLayout>
            ),
          },
          {
            path: SIMPLE_LOGIN_ROUTE,
            element: (
              <PageLayout public title='Welcome to I Speak' fallback={<LoginPlaceholder />}>
                <SimpleLogin />
              </PageLayout>
            ),
          },
        ],
      },
      {
        element: <AppLayout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: REGISTER_COMPANY_ROUTE,
            element: (
              <PageLayout public title='Welcome to I Speak' fallback={<LoginPlaceholder />}>
                <CompanyStart />
              </PageLayout>
            ),
          },
          {
            path: COMPANY_ACCOUNT_ROUTE,
            element: (
              <PageLayout protected title='Welcome to I Speak' fallback={<LoginPlaceholder />}>
                <CompanyAccount />
              </PageLayout>
            ),
          },
          {
            path: HOME_ROUTE,
            element: (
              <PageLayout protected title='Welcome to I Speak' fallback={<HomePlaceholder />}>
                <Home />
              </PageLayout>
            ),
          },
          {
            path: PROFILE_ROUTE,
            loader: profileLoader,
            element: (
              <Suspense fallback={<ProfilePlaceholder />}>
                <ProfileLayout />
              </Suspense>
            ),
            children: [
              {
                path: PERSONAL_ROUTE,
                element: (
                  <PageLayout
                    protected
                    title='Account - personal information'
                    fallback={<PersonalDetailsPlaceholder />}
                  >
                    <PersonalDetails />
                  </PageLayout>
                ),
              },
              {
                path: PROFILE_NOTIFICATION_ROUTE,
                loader: notificationLoader,
                element: (
                  <PageLayout
                    protected
                    title='Notifications'
                    fallback={<PersonalDetailsPlaceholder />}
                  >
                    <NotificationSettings />
                  </PageLayout>
                ),
              },
              {
                path: BIO_ROUTE,
                element: (
                  <PageLayout
                    protected
                    roles={['TEACHER']}
                    title='Account - My Homepage'
                    fallback={<BioPlaceholder />}
                  >
                    <Bio />
                  </PageLayout>
                ),
              },
              {
                path: CUSTOM_OPTION_ROUTE,
                element: (
                  <PageLayout
                    protected
                    roles={['TEACHER']}
                    title='Customization Options'
                    fallback={<BioPlaceholder />}
                  >
                    <TeacherOptions />
                  </PageLayout>
                ),
              },
              {
                path: TEACHER_CONNECT_ROUTE,
                element: (
                  <PageLayout
                    protected
                    roles={['TEACHER']}
                    title='Account Options'
                    fallback={<BioPlaceholder />}
                  >
                    <TeacherConnectAccount />
                  </PageLayout>
                ),
              },
              {
                path: TEACHER_PRICES,
                element: (
                  <PageLayout
                    protected
                    roles={['TEACHER']}
                    title='Teacher Prices'
                    fallback={<BioPlaceholder />}
                  >
                    <TeacherPrices />
                  </PageLayout>
                ),
              },
              {
                path: TEACHER_AFFILIATION_OFFERS,
                element: (
                  <PageLayout
                    protected
                    roles={['TEACHER']}
                    title='Teacher Affiliation'
                    fallback={<BioPlaceholder />}
                  >
                    <AffiliationOffers />
                  </PageLayout>
                ),
              },
              {
                path: FEEDBACK_SERVICES_ROUTE,
                element: (
                  <PageLayout
                    protected
                    roles={['TEACHER']}
                    title='Feedback Service'
                    fallback={<BioPlaceholder />}
                  >
                    <FeedbackService />
                  </PageLayout>
                ),
              },
              {
                path: VIDEO_ROUTE,
                element: (
                  <PageLayout
                    protected
                    roles={['TEACHER']}
                    title='Account - My videos'
                    fallback={<VideoPlaceholder />}
                  >
                    <Videos />
                  </PageLayout>
                ),
              },
              {
                path: LANGUAGES_ROUTE,
                loader: languagesLoader,
                element: (
                  <PageLayout
                    protected
                    title='Account - Languages'
                    fallback={<LanguagesPlaceholder />}
                  >
                    <Languages />
                  </PageLayout>
                ),
              },
              {
                path: BENEFIT,
                element: (
                  <PageLayout
                    protected
                    title='Account - benefit'
                    fallback={<ReferralLinkPlaceholder />}
                  >
                    <BenefitLink />
                  </PageLayout>
                ),
              },
              {
                path: PREFERRED_TEACHER,
                element: (
                  <PageLayout
                    protected
                    title='Account - Group Host'
                    fallback={<PreferredTeacherPlaceholder />}
                  >
                    <PreferredTeacher />
                  </PageLayout>
                ),
              },
              {
                path: AVAILABILITY_ROUTE,
                element: (
                  <PageLayout
                    protected
                    roles={['TEACHER']}
                    title='Account - availability'
                    fallback={<AvailabilityPlaceholder />}
                  >
                    <Availability />
                  </PageLayout>
                ),
              },
              {
                path: SUBSCRIPTION_ROUTE,
                element: (
                  <PageLayout
                    protected
                    title='Account - subscription'
                    fallback={<SettingsPlaceholder />}
                  >
                    <Subscription />
                  </PageLayout>
                ),
              },
              {
                path: SETTINGS_ROUTE,
                element: (
                  <PageLayout
                    protected
                    title='Account - settings'
                    fallback={<SettingsPlaceholder />}
                  >
                    <Settings />
                  </PageLayout>
                ),
              },
              {
                path: PREFERRED_ACCENT_ROUTE,
                element: (
                  <PageLayout
                    protected
                    title={'Account - preferred accent'}
                    fallback={<SettingsPlaceholder />}
                  >
                    <Accent />
                  </PageLayout>
                ),
              },
              {
                path: '*',
                element: (
                  <PageLayout title='Not found' fallback={<NotFoundPlaceholder />}>
                    <NotFound />
                  </PageLayout>
                ),
              },
            ],
          },
          {
            path: '/subscription',
            element: (
              <PageLayout protected title='Subscription' fallback={<SubscriptionPlaceholder />}>
                <SubscriptionIndividuals />
              </PageLayout>
            ),
          },
          {
            path: '/:teacher_url',
            loader: teacherHomeLoader,
            element: (
              <PageLayout title='Homepage' fallback={<HomePagePlaceholder />}>
                <HomePageTeacher />
              </PageLayout>
            ),
          },
          {
            path: MY_CLASSROOMS_ROUTE,
            element: (
              <PageLayout title='Events' protected fallback={<MyClassroomsPlaceholder />}>
                <MyClassrooms />
              </PageLayout>
            ),
          },
          {
            element: (
              <PageLayout title='Dashboard' protected fallback={null}>
                <DashboardLayout />
              </PageLayout>
            ),
            children: [
              // {
              //   path: OVERVIEW_ROUTE,
              //   element: (
              //     <PageLayout
              //       title='Dashboard - overview'
              //       fallback={<StickyNotesPlaceholder />} // TODO placeholder
              //     >
              //       <Overview />
              //     </PageLayout>
              //   ),
              // },
              {
                path: SUMMARY_ROUTE,
                children: [
                  {
                    index: true,
                    element: (
                      <PageLayout title='Dashboard - Summary' fallback={<StickyNotesPlaceholder />}>
                        <Summary />
                      </PageLayout>
                    ),
                  },
                  {
                    path: `${SUMMARY_ROUTE}/:feedback_id`,
                    element: (
                      <PageLayout title='Dashboard - Summary' fallback={<StickyNotesPlaceholder />}>
                        <SummaryById />
                      </PageLayout>
                    ),
                  },
                ],
              },
              {
                path: QUIZ_VOCAB_ROUTE,
                loader: vocabularyLoader,
                element: (
                  <PageLayout
                    title='Dashboard - Vocabulary'
                    fallback={<VocabularyPlaceholder />} // TODO placeholder
                  >
                    <QuizVocabularyView />
                  </PageLayout>
                ),
              },
              // {
              //   path: VOCABULARY_ROUTE,
              //   loader: vocabularyLoader,
              //   element: (
              //     <PageLayout
              //       title='Dashboard - overview'
              //       fallback={<VocabularyPlaceholder />} // TODO placeholder
              //     >
              //       <Vocabulary />
              //     </PageLayout>
              //   ),
              // },
              {
                path: NOTES_ROUTE,
                loader: notesLoader,
                element: (
                  <PageLayout
                    roles={['TEACHER']}
                    title='Dashboard - sticky notes'
                    fallback={<StickyNotesPlaceholder />}
                  >
                    <StickyNotes />
                  </PageLayout>
                ),
              },
              {
                path: COMMUNITY_ROUTE,
                children: [
                  {
                    index: true,
                    element: (
                      <PageLayout
                        title='Dashboard - Community'
                        fallback={<StickyNotesPlaceholder />}
                      >
                        <Community />
                      </PageLayout>
                    ),
                  },
                  {
                    path: `${COMMUNITY_ROUTE}/:user_id`,
                    element: (
                      <PageLayout
                        title='Dashboard - Community'
                        fallback={<StickyNotesPlaceholder />}
                      >
                        <UserId />
                      </PageLayout>
                    ),
                  },
                ],
              },
              {
                path: FEEDBACK_ROUTE,
                children: [
                  {
                    index: true,
                    element: (
                      <PageLayout title='Dashboard - Feedback' fallback={<FeedbackPlaceholder />}>
                        <Feedback />
                      </PageLayout>
                    ),
                  },
                  {
                    path: `${FEEDBACK_ROUTE}/:feedback_id`,
                    element: (
                      <PageLayout title='Dashboard - Feedback' fallback={<FeedbackPlaceholder />}>
                        <FeedbackById />
                      </PageLayout>
                    ),
                  },
                ],
              },
              {
                path: DOCUMENTS_ROUTE,
                loader: documentsLoader,
                element: (
                  <PageLayout
                    title='Dashboard - sticky notes'
                    fallback={<StickyNotesPlaceholder />}
                  >
                    <Documents />
                  </PageLayout>
                ),
              },
              {
                path: LIVE_BOARDS_ROUTE,
                loader: liveboardsLoader,
                element: (
                  <PageLayout title='Dashboard - live boards' fallback={<LiveboardsPlaceholder />}>
                    <Liveboards />
                  </PageLayout>
                ),
              },
              {
                path: WEBLINKS_ROUTE,
                element: (
                  <PageLayout title='Dashboard - live boards' fallback={<WeblinksPlaceholder />}>
                    <Weblinks />
                  </PageLayout>
                ),
              },
              {
                path: RECORDINGS_ROUTE,
                element: (
                  <PageLayout title='Dashboard - workshops' fallback={<WeblinksPlaceholder />}>
                    {/* <Recordings /> */}
                    <TestMyself />
                  </PageLayout>
                ),
              },
              // {
              //   path: TESTMYSELF_ROUTE,
              //   element: (
              //     <PageLayout title='Dashboard - test myself' fallback={<WeblinksPlaceholder />}>
              //       <TestMyself />
              //     </PageLayout>
              //   ),
              // },
              {
                path: '*',
                element: (
                  <PageLayout title='I Speak - Page not found' fallback={<NotFoundPlaceholder />}>
                    <NotFound />
                  </PageLayout>
                ),
              },
            ],
          },
          // {
          //   path: COMMUNITY_ROUTE,
          //   element: (
          //     <PageLayout title='Community' protected fallback={<CommunityPlaceholder />}>
          //       <Community />
          //     </PageLayout>
          //   ),
          // },
          // {
          //   path: GROUP_LESSON_ROUTE(':id'),
          //   loader: groupLessonLoader,
          //   element: (
          //     <PageLayout title='Group Lesson' protected fallback={<CommunityPlaceholder />}>
          //       <GroupLesson />
          //     </PageLayout>
          //   ),
          // },
          {
            path: PRICING_ROUTE,
            // loader: pricingLoader,
            element: (
              <PageLayout title='SpeakDuo - Pricing' fallback={<PricingPlaceholder />}>
                <Pricing />
              </PageLayout>
            ),
          },
          {
            path: PAYMENT_ROUTE,
            element: (
              <PageLayout protected title='SpeakDuo - Payment' fallback={<PricingPlaceholder />}>
                <Payment />
              </PageLayout>
            ),
          },
          {
            path: CHECKOUT_SUCCESS_ROUTE,
            element: (
              <PageLayout
                protected
                title='SpeakDuo - Checkout Success'
                fallback={<PaymentSuccessPlaceholder />}
              >
                <PaymentSuccess />
              </PageLayout>
            ),
          },
          {
            path: FIND_A_TEACHER_ROUTE,
            element: (
              <PageLayout
                protected
                title='SpeakDuo - Find a teacher'
                fallback={<FindATeacherPlaceholder />}
              >
                <FindATeacher />
              </PageLayout>
            ),
          },
          {
            path: '*',
            element: (
              <PageLayout title='Not found' fallback={<NotFoundPlaceholder />}>
                <NotFound />
              </PageLayout>
            ),
          },
        ],
      },
      {
        element: <EventLayout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: NEXT_EVENT,
            loader: liveEventLoader,
            element: (
              <PageLayout
                simple
                protected
                title='SpeakDuo - classroom'
                fallback={<EventPlaceholder />}
              >
                <LiveEvent />
              </PageLayout>
            ),
          },
          {
            path: `${EVENT_ROUTE}/:event_id`,
            loader: eventLoader,
            element: (
              <PageLayout
                simple
                protected
                title='SpeakDuo - classroom'
                fallback={<EventPlaceholder />}
              >
                <Event />
              </PageLayout>
            ),
          },
        ],
      },
    ],
  },
])

export default router

// TODO : add site map
export const siteMap = {
  // HomePage: {
  //   title: "Home",
  //   path: "/",
  //   description: "My home page",
  // },
  // SubPage: {
  //   title: "Sub Page",
  //   path: "/sub-page",
  //   description: "My sub page",
  // },
}
